.bg-full{
    height:100vh!important;
    width:100vw!important;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    position:absolute;
    z-index:99999;
}

#progressbar{
    height: 10px;
    border:solid 1px #a3a3a3;
    position: absolute;
    width: 320px;
    border-radius:6px;
    padding:0;
    margin:0;
    background:#dfdfdf;
}
#loading{
    transition: all 100ms ease;
    height: 6px;
    width: calc(90% - 10px);
    border-radius: 4px;
    background: #a3a3a3;
    position: absolute;
    margin:2px;
    display: inline-block;
    animation: load 100s ease infinite;
}
@keyframes load{
    0%{ width: 0%;}
    10%{ width: 10%;}
}

.cont-logo-proximamente{
    height:240px;
    width:240px;
}
.cont-logo-proximamente img{
    object-fit: scale-down!important;
    height:100%;
    width:100%;
}
.text-construccion{
    font-size: 54px;
    line-height: 1;
    text-align: center;
    font-weight: 500;
}
.text-construccion-min{
    font-size: 32px;
    line-height: 1;
    text-align: center;
    font-weight: 400;
}
.width360{
    width:320px
}
.cont-porcent{
    margin-top:40px; position:absolute
}


/* --------------------MEDIA QUERIES  ------------------*/

@media (max-width: 1600px){/*Tamaño xl*/
}
@media (max-width: 1200px){
    .movil-no{
        display:none;
        height:0;
        width:0;
    }
    .bg-full-fondo{
        height:100vh!important;
        width:100vw!important;
        background-color:rgba(255, 255, 255, .4);
    }
}
@media (max-width: 960px){ /*Tamaño m*/
    .text-construccion-min{
        font-size: 25px;
    }
}
@media (max-width: 820px){
}
@media (max-width: 640px){/* Tamaño s*/
    
}
