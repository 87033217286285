
#root { 
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	justify-content: space-between;
}
.fullContainer{
    flex-grow: 1;
}

.m-p-0{
    margin:0;
    padding:0;
}
.pt-40{padding-top:40px;}
.pbt-30{padding:30px 0!important;}
.height33{height:33px!important}
.footerCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}
.footerbackground {
    position: relative;
    z-index:2;
}
.footerForm{
    width:28%;
}
.footerText{
    font-size: 14px!important;
}
.cont-footer-form{
    width:26%;
    padding:0 8px;
}
.cont-footer{
    width:24%;
    margin:0;
    padding:0;
    padding:0 2% 0 4%!important;
}
.cont-footer-link{
    width:16%;
    padding:0 8px;
}
.footerTitle{
    color:#ffffff;
    font-weight:700;
    font-size: 16px;
    line-height: 1;
    padding:2px 0 8px 0;
}
.footerForm{
    font-weight:500;
    font-size: 14px;
    margin: 0;
    padding:0;
}

.cont-footer-form form div textarea{
    padding: 5px 10px!important;
    width:100%;
}
.footerForm-input{
    background-color: #ffffff!important;
    padding: 5px 10px!important;
}
.footer-input{
    background-color: #ffffff!important;
    margin-left:10px!important;
    padding: 0 10px!important;
}
footer input{
    width:93%!important;
}
.footerForm-input-btn{
    background-color: #E2E2E2!important;
}
.footerForm-input-btn i{
    color:#999999;
}
.footerTexto{
    font-weight:500;
    font-size: 12px;
    margin: 0;
    padding:0;
}
.footerTex{
    font-weight:400;
    font-size: 12px;
    margin: 0;
    padding:0;
}
.footerLink{
    font-weight:500;
    font-size: 14px;
    line-height: 1;
    margin: 0;
    padding:6px 0;
    display: flex;
    justify-content:left;
    align-items: center;
}
.footerLink .iconRed{
    margin-bottom:-2px;
    font-size:16px;
}
.footerLink:hover{
    font-weight:600;
    text-decoration: underline;
}
.btn-footer-send{
    background-color:#F6C62D;
    color:#4D4D4D;
    font-weight:600
}
.btn-footer-news{
    height:33.8px!important;
}
.btn-footer-news i{
    font-weight:600!important;
}
.footer-inferior{border-top:solid 1px #4D4D4D;}
