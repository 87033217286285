.navbackground {
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height:90px;
    padding:10px 20px;
    width:100%;
}
.container-menu{ 
    height:100px;
    margin:0;
    padding:0;
    width:100%;
}
.container-menu .cont-ul{
    display: flex!important;
    justify-content: center;
    align-items: center;
    width:100%;
    line-height: 0;
}
.container-menu .cont-ul li img{
    height: 80px;
    margin:0;
    padding:0;
}
.active-negro{color:#000000}
.activar-carrito{color:#000000!important}
.container-menu .cont-ul li .link-a{
    font-size:14px;
    font-weight: 500;
    margin:0;
    padding:10px 4px;
    text-align: center;
    color: #ABABAB;
    line-height: .9;
}
.container-menu .cont-ul li .link-a:hover{
    font-weight: 500;
    background-color:transparent;
    color:grey;
}
nav ul a:hover {
    background-color: rgba(0, 0, 0, 0);
}
nav ul .logo a:hover {
    background-color: rgba(0, 0, 0, 0);
}
.container-menu .cont-ul .logo{
    width: auto;
}
.container-menu .cont-ul .item{
    width: 180px;
}
.container-menu .cont-ul .buscar{
    /*padding-top: 12px;*/
    width: 100%;
}
#searchI{height:35px!important;}
.item-nav-active{
    width:86%;
    border-bottom:solid #000000;
    text-align:center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: inline-block;
}
.item-name-active{
    width:100%!important;
    padding:5px 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 14px;
    display: block;
}
.buscar-a{padding:0;
    margin: 0;
    padding:0;
}
.container-menu .cont-ul .buscar i{
    border-radius: 0px 22px 22px 0px!important;
    height: 40px;
    line-height: 40px;
    margin-top: -7px!important;
    padding: 0;
    text-align: center;
    width: 50px;
}
.container-menu .cont-ul .buscar label{
    display:'flex';
    height:35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0 10px;
    position:relative;
}
.container-menu ul .buscar .inpu-text{
    height:35px;
    border-radius:24.5px 0px 0px 24.5px; 
    padding-left:10px;
}
.container-menu ul .compras{
    width: 180px;
}
.container-menu ul .login{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
}
.container-menu ul .login a{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.container-menu ul .login a i{
    font-size: 30px;
}
.container-menu ul .buy{
    position:relative;
    width: 180px;
    line-height: .9;
}
.container-menu ul .buy a{
    display: flex;
    justify-content: center;
    align-items: center;
}

.articulos{
    border-radius:100%; 
    font-size:11px!important;
    height:22px!important; 
    position:absolute;
    left:12px!important;
    width:22px!important;
    min-width: 22px!important;
    padding:0!important;
    top:-5px!important;
}
.buy .link-a span .articulos{
    height: 22px!important;
    width: 22px!important;
    min-width: 22px!important;
}
.container-menu ul li link i,
.container-menu ul li a .car{
    font-size:28px;
    height: auto;
    margin:0;
    margin-top:18px;
    line-height: 0;
    padding:2px 4px;
    text-align: center;
} 
.number-badge{
    position: absolute;
    top:-25px !important;
}
.container-menu ul li link span span{
    border:solid green;
}
.border-input{
    border:solid 3px #F8F8F8!important;
    background-color: #F8F8F8!important;
    border-right: solid 1px transparent!important;
    border-radius:24.5px 0px 0px 25.5px!important;
    padding:0 20px!important;
}
.label-search input[type=search]:not(.browser-default):focus:not([readonly]), 
.border-input:focus {
    outline: none!important;
    border:solid 3px #F8F8F8!important;
    border-bottom: 1px solid #F8F8F8!important;
    box-shadow: 0 1px 0 0 #F8F8F8!important;
}
.es-movil{display:none!important;
    height:0!important;
    width:0!important;
    opacity:0!important;
    margin: 0;
    padding:0;
}
.es-pc{display:block!important;
    height:auto!important;
    width:auto!important;
    opacity:1!important;
    margin: 0;
    padding:0;
}
.es-nav{display:block!important;
    height:auto!important;
    opacity:1!important;
    margin: 0;
    padding:0;
}
#nav-buscar{
    padding:4px 6px!important;
}
#nav-buscar label{
    margin: 0!important;
    padding:0!important;
    width:100%!important;
}
#nav-buscar label input{
    margin: 0!important;
    padding:0 10px!important;
    position: relative!important;
    width:87%!important;
}
#nav-buscar label .btn-buscar-movil{
    height: 3.4rem;
    margin: 0!important;
    padding:6px!important;
    position: absolute!important;
    width:13!important;
    right: 10px;
    border-radius: 0px 22px 22px 0px!important;
}
#nav-buscar label{
    margin: 0!important;
    padding:0!important;
    width:100%!important;
}
@media (max-width:1200px){
    .item-name-active,
    .container-menu .cont-ul li .link-a{
        font-size:12px;
    }
    .container-menu .cont-ul .buscar label{
        padding:0;
    }
    .container-menu .cont-ul li img{
        height: 70px;
        margin: 0;
        padding: 0;
    }
    
}

@media (max-width:992px){ 
    .container-menu .cont-ul{ margin-top:-10px;}
    .navbackground{height: 70px;}
    .cont-ul{
        width: 100vw!important;
    }
    .es-movil{display:block!important;
        height:auto!important;
        width:auto!important;
        opacity:1!important;
        margin: 0;
        padding:0;
    }
    .es-nav,
    .es-pc{display:none!important;
        height:0!important;
        width:0!important;
        opacity:0!important;
        margin: 0;
        padding:0;
    }
    .sidenav-overlay{background-color:transparent;}   
    .movil-buscar{
        position: relative;
        right: -1px;
        top: -52px;
        float: right;
        padding: 10px;
        border-radius: 0px 10px 10px 0px!important;
    } 
    .cont-footer{
        width: 100%;
        margin:0;
        padding:18px 0!important;
    }  
    .cont-social{
        width: 100%!important;
        margin:0;
        padding:18px 0!important;
    } 
    .cont-footer-link {
        width: 48%;
        margin:0;
        padding:18px 0!important;
    }
    .container-menu .cont-ul .buscar i{
        margin-top: 0px!important;
    }
}
@media (max-width:769px){
    nav .sidenav-trigger{margin: 0 5px;}
    .container-menu .cont-ul li .link-a{
        padding:0;
    }
    .container-menu ul .buy,
    .container-menu ul .login{
        width:100px;
    }
    .navbackground{padding:0;}
    nav .nav-wrapper { width:100%!important; };
    .container-menu ul li link i, 
    .container-menu ul li a .car {
       border:solid pink 5px!important;
    }
    nav ul a {padding:5px;}
    /*.logo-img{
        margin-top: 5px!important;
    }*/
}
@media (max-width:480px){
	.container-menu .cont-ul li img{
		height: 50px;
		margin:0;
		padding:0;
	}
	/*.logo-img{
 		margin-top: 10px!important;
	}*/
}
