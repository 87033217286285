.banner-txt-cont{
/*margin:0 auto;*/
    width: 428px;
    height: 414px;
    margin-top: 150px;
}
.cont-text-banner{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.back-img{
    background-size: cover!important;
    background-position: center!important;
    transform:none!important;
}
.banner-title{
    margin: auto;
    font-weight: 900!important;
    font-size: 36px!important;
    line-height: 36px!important;
    color:white;
    text-transform: uppercase;
    font-family: 'Source Sans Pro', sans-serif;
}
.banner-subtitle{
    margin: auto;
    padding: 5px 0;
    font-weight: 900!important;
    font-size: 46px!important;
    line-height: 46px;
    color:white;
    text-transform: uppercase;
	font-family: 'Source Sans Pro', sans-serif;
}
.banner-txt{
    margin: auto;
    margin-top:10px;
    /*border-top: 2px solid white;*/
    font-size: 16px!important;
    width: 353px;
    padding: 10px 0;
    text-align: justify;
    color:white;
    font-weight: 400;
}
.banner-btn{
    padding:10px;
    border-radius: 5px;
    width: 355px;
    min-height:40px;
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
}
.container-prod{
    margin:0 auto!important;
    padding:10px!important;
}
.container-blanco{background-color:#ffffff;
    margin: 0 auto!important;
    padding:20px!important;
    border-radius:6px;
}
.nombre-clasificacion{
    text-transform: uppercase;
    font-weight:900;
    font-size: 22px;
    padding-bottom:13px;
    letter-spacing: -1px;
}
/*api/catalogo_departamento*/
.img-info{height:80vh;}
.espacio{background-color:#F5F5F5; padding-top:60px}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next,
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    margin:0;
    padding:10px;
    background-color:#ffffff;
    width: 70px!important;
    height:90px!important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:6px;
    margin-right: -22px;
    margin-left: -22px;
}
.swiper-button-prev, .swiper-button-next{
    margin: 0; padding:0;
}
.swiper-button-prev{
    margin-left: -10px;
}

.swiper-button-next{
    margin-right: -10px;
}
@media (max-width:1440px){
    .img-info{height:65vh;}
}
@media (max-width:1200px){
    .img-info{height:35vh}
}
@media (max-width:1000px){
    .img-info{height:30vh}
    .portada{
	height:300px !important;
    }
}
@media (max-width:840px){
    .img-info{height:50vh}
}
@media (max-width:640px){
    .img-info{height:50vh}
}
/*@media (max-width:550px){
    .img-info{height:15vh}
}
@media (max-width:375px){
    .img-info{height:15vh}
}*/
.swiper-button-next, .swiper-button-prev {
    top: 40% !important;
    color: black;
    width:50px !important;
    height:60px !important;
    --swiper-theme-color: black;
}
