.m-p-0{
    margin:0!important;
    padding: 0!important;
}
.m-0{
    margin:0!important;
}
.p-0{
    padding: 0!important;
}
.center-y-x{
    display: flex;
    justify-content: center;
    align-items: center;
}
.height80{
    min-height: 80vh;
}
.card-panel{
    background-color: #FCFCFC;
}
.orange-link{
    font-weight: 500;
    font-size:14px;
    color:#DF3F0C;
}
.cont-user{
    border-radius: 100%;
    height:80px;
    width: 80px;
}
.input-field>label{
    transform: translateY(1px);
}
.active{
    color:#000000!important;
    font-weight:bold;
}
    
.orange-link{
    font-weight: 500;
    font-size:14px;
    color:#DF3F0C;
}

/* --------------------MEDIA QUERIES  ------------------*/

@media (max-width: 1600px){/*Tamaño xl*/
}
@media (max-width: 1200px){
}
@media (max-width: 960px){ /*Tamaño m*/
}
@media (max-width: 820px){
}
@media (max-width: 640px){/* Tamaño s*/
    .card-panel{
        margin: 0 20px;
    }
}

