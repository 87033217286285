.container-detalle{
    padding:50px
}

.migaja1{
    display: inline-block; 
    vertical-align:top; 
    width: auto; 
    padding:2px;
    padding-left:10px;line-height: .5;}
.migaja1 a{
    margin:0!important;padding:0!important;
    width:100%;
    font-size:16px; 
    font-weight:bold; 
    color:#000000; 
    font-size:16px; 
    font-weight:bold; 
    text-transform: uppercase;line-height:.5;}
.migaja2 a{margin:0!important;padding:0!important;width:100%;color:#000000; 
    font-size:16px; 
    font-weight:bold; 
    text-transform: uppercase;line-height: .5;}
.migaja2{
    display: inline-block; 
    vertical-align:top; 
    width: auto; 
    padding:2px;line-height: 0;}
.migaja3{display:inline-block;
    color:black;
    font-size:16px;
    font-weight:bold;
    display: inline-block; 
    vertical-align:top; 
    width: auto; 
    padding:6px!important;
    text-transform: uppercase;line-height: 0;
}
.contain-detail{
    padding:30px 20px;
    width:100%;
}
.min-img-lateral{
    cursor:pointer;
    border: solid 1px transparent;
    padding:3px;
    height:60px;
    width:60px;
}
.min-img-lateral img{
    object-fit:scale-down;
    height:100%;
    width:100%;
}
.cont-img-prod{
    height:35em;
    width:100%;
}
.cont-img-prod .material-placeholder{
    height:100%;
    width:100%;
}
.cont-img-prod img{
    height:100%;
    width:100%;
    object-fit:cover;
}
.cont-minimg{
    cursor:pointer;
    height:50px;
    width: 50px;
}
.cont-minimg img{
    height:100%;
    width:100%;
}
.btn-comprar{
    margin-right:20px;
    font-size:14px!important;
    font-weight:200!important;
    display:inline-block;
    text-transform:none;
    width:223px; 
}
.btn-agregar{
    align-items:center;
    display:inline-block!important;
    font-size:14px!important;
    font-weight:200!important;
    text-transform:none; 
    width:223px!important; 
    justify-content: flex-end;
}
.btn-add{
    align-items:center;
    display:inline-block!important;
    font-size:14px!important;
    font-weight:200!important;
    text-transform:none; 
    width:90%!important; 
    justify-content: flex-end;
}
.nombre-prod{
    font-size: 18px;
    font-weight: bold;
}
.swiper-pagination-bullet-active{
	background:white;
}
@media (max-width:1440px){
    .min-img-lateral{
        height:50px;
        width:50px;
    }
    .cont-img-prod{ height:30em; }
}
@media (max-width:1200px){
    .container-detalle{
        padding:30px
    }
    .min-img-lateral{
        height:40px;
        width:40px;
    }
    .cont-img-prod{ height:20em; }
    .nombre-prod{font-size:16px;}
    .btn-agregar,
    .btn-comprar{width:200px!important;}
}
@media (max-width:990px){
    .min-img-inferior{
        display:inline-block;
        cursor:pointer;
        padding:4px 2px;
        height:40px;
        width:40px;
    }
    .min-img-inferior img{
        height:100%;
        width:100%;
    }
    .contain-detail{ padding:0px;}
    .cont-img-prod img{padding-right:0}
    .cont-img-prod{ height:24em; }
    .nombre-prod{font-size:14px;
        font-weight: bold;
    }
    .btn-agregar,
    .btn-comprar{width:100%!important;margin:6px 0; padding:0;}
    .migaja1, .migaja2{
        font-size:14px;}
}
@media (max-width:768px){
    .cont-img-prod{ height:20em; }
    .container-detalle{
        padding:8px
    }
    .migaja1, .migaja2{
        font-size:11px!important;
        /*display: block!important;
        width: 100%!important;*/
        padding:2px 4px;
    }
    .migaja1 a, .migaja2 a, .migaja3{
        font-size:11px;
    }
}
@media (max-width: 660px){
    .cont-img-prod {
        height: 18em;
    }
    .nombre-prod{font-size:18px;}
    .product-title-responsive p, .min-img-inferior{
	display:none;
    }
}
@media (max-width:550px){
    .cont-card-movil{ width:50%!important; }
    .container-detalle{
        padding:5px
    }
    .cont-img-prod{ height:24em; }
}
@media (max-width:375px){
    .cont-card-movil{ width:100%!important; }
}
@media (max-width:320px){
    .cont-img-prod{ height:15em; }
}
