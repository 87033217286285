.tabMargin20{margin:20px;}
.tags{
    font-size:16px; 
    text-align:center;
}
.dirPadding{
    padding:30px 82px 120px 82px;
}

.tituloDireccion{font-size:28px;font-weight:500;margin-bottom:20px;margin-left: 10px;}
.btn-agregar{
    color:#ffffff;
    margin-left:auto!important;
    display:block;
    width:180px;
    font-size:12px;
    font-weight:500;
    text-transform:none;
    font-style:normal;
    padding:2px;
    margin:0;
    text-align:center;
}
.btn-continuar{
    display:block;
    margin:22px 0 0 auto;
    height: 49px;
    padding: 10px;
    width:261px;
    font-size: 16px;
    font-style: 700;
}
.card-panel{
    margin:10px
}
.card-panel-input{
    height:37px!important;
    border:1px solid #d6d6d6!important;
}

@media (max-width:1200px){
    .tags{ font-size:14px; } 
    .tituloDireccion{font-size:24px;}  
}
@media (max-width:990px){
    .tags{ font-size:12px; }
    .tituloDireccion{font-size:18px;} 
    .dirPadding{ padding:20px;}
    .btn-continuar{width:33%!important;}
}
@media (max-width:840px){
    .datosForm{width:100%!important;}
    .card-panel{padding:15px;}
    .pago-100{width:100%!important;}
    .tabMargin20{font-size:14px;}
    .btn-agregar{width: 30%!important;}
    .btn-continuar{width:50%!important;}
    .paqueteria{width:50%!important;}
}
@media (max-width:640px){
    .tituloDireccion{font-size:14px;} 
    .dirPadding{ padding:15px;} 
    .tabMargin20{font-size:14px;margin:10px;}
    .card-panel{padding:8px;}
    .btn-agregar,
    .btn-continuar{width: 100%!important;}
    .btn-movil{margin:0;margin-left:4px!important;width:80vw;font-size:14px!important;font-weight:400!important;padding:5px!important;height:40px;margin-top:10px;}
    .paqueteria{width:50%!important;}
}
@media (max-width:677px){
    .tabMargin20{margin:4px;}
}
@media (max-width:600px){
    .datosForm input{width:100%!important;}
}
@media (max-width:550px){
    .tituloDireccion{font-size:14px;} 
    .dirPadding{ padding:15px 10px 10px 10px;}
    .tabMargin20{font-size:12px;margin:6px;}
    .paqueteria{width:100%!important;}
}
@media (max-width:480px){
    .tabMargin20{font-size:10px;margin:4px;}
}
@media (max-width:395px){
    .tabMargin20{font-size:9px!important;font-weight:500;margin:3px!important;}
}
@media (max-width:347px){
    .tabMargin20{font-size:8px!important;font-weight:600;margin:3px!important;}
}