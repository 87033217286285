.footer-center{padding:0 16%;}
.footer2-movil{width:25%;}
.footer2-movil-col{
    width:25%;
}
@media (max-width:2500px){
    .footer-center{padding:0 10%;}
}
@media (max-width:1500px){
    .footer-center{padding:0 2%;}
}
@media (max-width:992px){ 
    .footer2-movil{
        width:100%;
    }
    .red-center{
        align-items: center;
        justify-content: center;
        text-align:center!important;
    }
    .red-right{justify-content: flex-end;}
    .footer2-movil-col {
        width:50%;
    }
}
@media (max-width:768px){ 
    .red-center-red{text-align: center;}
    .red-center{
        align-items: right;
        justify-content: right;
        text-align:right!important;
    }
    .footer2-movil{
        width:50%;
    }
    .pt-40 {
        padding-top: 0px!important;
    }
}
@media (max-width:640px){ 
    .footer2-movil-col{
        width:100%;
    }
    .cont-footer-link {
        width: 100%;
    }
}