.perfil-header{
    height:225px;
    background-color: #FCFCFC;
    padding: 0 18px;
}
.perfil-img-cont{
    display:flex;
    justify-content:left;
    align-items:center;
    width: 250px;
    height:100%;
    padding:0!important;
}
.pedidos-btn{
    margin: 20px 0 10px 0;
    padding-left: 10px;
    width: 185px;
    height: 40px;
    color: black;
    background: #ECECEC;
    opacity: 0.9;
    border: 1px solid #ABABAB;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}
.pedidos-btn span{
  margin-right:20px;
}
.perfil-list{
    display: flex;
    align-items: center;
    color: #4D4D4D;
    height: 55px;
    border-bottom: 1px solid #F8F8F8;
}
.perfil-list a{
    color: #4D4D4D;
    font-weight: 400;
    font-size: 14px;
}
.borde{
    border: 1px solid #F8F8F8;
}
.logout-container{
    display: flex;
    align-items: center;
    color:#ABABAB;
    font-size: 12px;
    cursor: pointer;
   /** bottom: 0;
    position: absolute;**/
}
.padding-5{
    padding:20px!important;
}
.margin-cont{
    margin-top: 50px;
}
.edit-perfil-icon{
    display:none;
}
.perfil-picture:hover .edit-perfil-icon{
	display:block;
}
.user-info-container{
    margin-left:15px;
    height:100%;
}
.perfil-icon{
    font-size: 1.5em!important;
    color: #BBBBBB;
}
.perfil-icon:hover{
    color: #4D4D4D;
}
.tab-btn{
    cursor: pointer;
    background:#E8EFFC;
    height:51px;
    width: 266px;
    border:1px solid #9FADC2;
    border-radius: 5px;
    position: relative;
    display: flex;
    padding: 0 20px;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}
.tab-btn-right{
    float: right;
}
.tab-btn-left{
    float: left;
}
.tab-btn:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.money-icon{
    font-size:22px;
    margin: auto;
    position:absolute;
    right:20px;
    height:25px;
    width: 25px;
    border:solid black 2px;
    color: white;
    border-radius: 100%;

    background-color:#5CA178;
}
.user-mgs{
    margin: 0 20px;
    background: #FFFFFF;
    padding: 5px;
    width: 25px;
    height: 25px;
    font-size: 14px;
    padding: 3px;
    color: #CB4145;
    border-radius: 100%;
}

@media (max-width: 1600px){/*Tamaño xl*/
}
@media (max-width: 1200px){
    .pedidos-btn{
        width: 100%;
    }
    .pedidos-btn span{
        margin-right:25px;
    }
    .tab-btn{
        width: 230px;
    }
}
@media (max-width: 960px){ /*Tamaño m*/
    .tab-btn{
        width: 210px;
    }
}
@media (max-width: 820px){
    .pedidos-btn{
        padding-left:5px;
        width: 140px;
        height: 40px;
        align-items: center;
    }
    .pedidos-btn span{
        margin-right:20px;
    }
    .tab-btn{
        padding: 0 10px!important;
    }
    .tab-btn-right{
        float: none;
    }
    .tab-btn-left{
        float: none;
    }
}
@media (max-width: 640px){/* Tamaño s*/
    .perfil-header{
        height:auto;
    }
    .perfil-img-cont{
        justify-content:center;
        align-items:center;
    }
    .perfil-img{
        margin: 10px;
        border: 5px solid white;
    }
    .pedidos-btn{
        padding-left:10px;
        width: 100%;
        height: 40px;
    }
    .pedidos-btn span{
        margin-right:10px;
    }
    .margin-cont{
        margin-top: 10px;
    }
    .perfil-list{
        height:40px;
        border-bottom: 1px solid #F8F8F8;
    }
    .card{
        margin: 1rem 0 1rem 0;
    }
    .tab-btn{
        width: 95%;
        margin: auto!important;
    }
    .tab-btn-right{
        float: none;
    }
    .tab-btn-left{
        float: none;
    }

}
