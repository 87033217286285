.popover__title {
	text-decoration: none;
	color: #DF3F0C;
	text-align: center;
	padding: 15px 0;
}
 
.popover__wrapper {
	position: relative;
	margin-top: 1.5rem;
	display: inline-block;
}
.popover__content {
	opacity: 0;
	visibility: hidden;
	position: absolute;
	left: 0px;
	transform: translate(0, 10px);
	background-color: white;
	padding: 1.5rem;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	width: 200px;
	border-radius:20px;
}
.popover__content:before {
	position: absolute;
	z-index: -1;
	content: "";
	right: calc(50% - 10px);
	top: -8px;
	border-style: solid;
	border-width: 0 10px 10px 10px; 
	border-color: transparent transparent white transparent;
	transition-duration: 0.3s;
	transition-property: transform; 
}
.popover__wrapper:hover .popover__content {
	z-index: 10;
	opacity: 1;
	visibility: visible;
	transform: translate(0, -20px); 
	transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message {
	font-size:12px;
	color:black;
}
