.barra{background-color: #F8F8F8;
    padding:20px 40px!important;
    font-size:18px;
    font-weight: 500;
    margin:0;
}
.bg-container{ background-color: #f5f5f5; }
.ul-tabs{background-color:transparent;}

.tabs .tab {
    line-height: 60px;
}

.tabs .tab a{
    color:#A1A1A1;
    padding:0 12px;
    font-size:16px;
    font-weight:500!important;
    text-transform: uppercase;}
.tabs .tab a:hover{color:#D6D6D6;}
.tabs .tab a:focus, .tabs .tab a:focus.active {
    background-color:transparent;
    color: #000000;
    font-weight: 900!important;
    outline: none;
    margin-top: 0;
}
.tabs .indicator{background-color: #000000;height: 3px;}
.active {margin-top: 0;font-weight:900!important;}
.container-tab{padding:14px!important;}
.tabs-info{ margin: 0;
    border:solid 1px #D6D6D6;
    font-size: 14px;
    font-weight:100;
}
.bloque1{ padding:20px!important;}
.bg-blanco{background-color: #ffffff;}
.text{font-size:14px;font-weight:500;color:#4D4D4D;}
.textos{font-size:14px;font-weight:400;line-height: 15px;}
.status{font-size:12px;font-weight:400;color:#DF3F0C;}
.destinatario{font-size:12px;font-weight:400;color:#269FCF;}
.bloque2{ margin: 0;
    padding:25px 25px 0 25px!important;;
    background-color:#ffffff;
}
.negro{font-size:16px;font-weight:500;color:#000!important;}
.img-prod-hover{height:190px;background-color:transparent;margin-bottom:-190px;position:relative;z-index:1;
    display: flex;
    justify-content: center;
    align-items: center;
    color:transparent;font-weight:800;font-size:12px}
.ligaProd{margin:0!important; padding:0!important; width:100%; }
.ligaProd:hover .img-prod-hover{background-color:rgba(255, 255, 255, .6);color:#000000;}

.img-prod{height:190px;padding:6px;position:relative;z-index:0}
.img-prod img{max-height:100%; max-width:100%;margin:0;padding:0;}
.m-0{margin:0;}
.pad-0-14{ padding:0 14px!important;}
.pad-14{ padding:14px!important;}
.pad-10-0{ padding:10px 0!important;}
.pad-5-0{ padding:5px 0!important;}
.pad-b-20{padding-bottom:20px!important;}
.font-b-700{font-weight: 700;}
.font-b-500{font-weight: 500;}
.font-b-300{font-weight: 300;}
.font-b-100{font-weight: 100;}
.font-12{font-size:12px;}
.font-14{font-size:14px;}
.font-16{font-size:16px;}
.cont-icon-activo{background-color: #4D4D4D!important;}
.cont-icon{background-color:#ABABAB;
    border-radius: 100%;
    padding:2px;
    width: 20px;
    height:20px;
    display: inline-block;
    margin-right:10px;
}
.scroll-descripcion{
    height:100px;
    /*overflow-y:scroll;*/
}
.scroll-detalle{height:150px;overflow-y:scroll;}
.cont-icon i{color:#ffffff}
.btn-negro{background-color: #000000;
    font-size:14px;
    font-weight: 500;
    padding:11px 0px!important;
    margin-top:-50px!important;
    color:#ffffff;
    border:solid transparent;
}
.btn-negro:hover{
    background-color:#4D4D4D;
    color:#ffffff;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}
.btn-azul{background-color: #269FCF;
    padding:7px!important;
    color:#ffffff;
    border:solid transparent;
    text-align:center;
    width:90%;}

.btn-azul:hover{
    background-color: #3dafdb;
    color:#FFFFFF;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}
.border-top{border-bottom:solid 1px #E5E5E5;padding: 5px 0 0 14px!important;}
.scroll-item{height: 220px;
    overflow-y: scroll;
    scroll-behavior: smooth;}
.scroll-prod{
    height:205px;
    overflow-y:scroll;
    overflow-x: hidden;
}
.pedido-card-fecha{
    font-size: 16px!important;
}
.card-pedido-lg{
    border-top:1px solid #d6d6d6;
    border-bottom:1px solid #d6d6d6;
    padding:5px 0!important;
}
.card-tittle{
    font-size: 14px;
    font-weight: 500;
    margin:5px 0;
    padding: 0;
}
.card-description{
    color:#818181;
}
.img-card-pedido-cont{
    height:190px!important;
    padding:4px;
    text-align: center;
}
.img-card-pedido{
    max-height:190px;
}
/* ----------- MEDIA QUERY  */
@media (max-width: 1600px){/*Tamaño xl*/
}
@media (max-width: 1500px){
    .scroll-descripcion{
        height:100px;
    }
    .img-prod img{margin-top: 10px;}
}
@media (max-width: 1200px){/*Tamaño l*/
    .scroll-descripcion{
        height:62px;
    }
}
@media (max-width: 960px){ /*Tamaño m*/
    .bloque2{
        padding: 10px!important;
    }
    .cont-result{
        padding:20px;
    }
    .img-prod{
        height: 140px!important;
    }
    .scroll-item{
        height: auto;
    }
    .scroll-descripcion {
        height: 55px;
        overflow-y: scroll;
    }
    .scroll-prod{
        height: 170px;
    }
    .pad-b-20{
        padding-bottom: 5px!important;
    }
    .btn-negro{
        padding: 10px!important;
        margin-top:5px!important;
    }
    .btn-azul-mobile{
        text-transform:none;
        font-size:12px;
        font-weight:300;
    }
    .img-card-pedido-cont{
        height:140px!important;
        text-align: center;
    }
}
@media (max-width: 820px){
}
@media (max-width: 640px){/* Tamaño s*/
    .cont-result{
        padding:10px;
    }
    .img-prod{
        height: 120px!important;
    }
    .scroll-item{
        height: auto;
    }
    .scroll-descripcion {
        height: 75px;
        overflow-y: scroll;
    }
    .border-top{
        padding:0;
    }

    .tittle-mobile-center{
        text-align: center;
    }
    .card-pedido-lg{
        border:none;
    }
    .scroll-prod{
        overflow-y:hidden;
    }
    .img-card-pedido-cont{
        height: 170px!important;
        text-align: center;
    }
    .mobile-status{
        margin: 5px 0!important;
        text-align: center;
    }
}
