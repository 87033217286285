/*
   * Global				
 */																																																										
body{
	margin: 0;		 
	font-family: 'Roboto', sans-serif;
	font-style: normal;
	font-weight: normal;
	-webkit-font-smoothing: subpixel-antialiased !important;
	z-index: -1;	   
	background: white;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	justify-content: space-between;
}
/*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #000000;
}
*/

/*.card-departament{
	.card-content{
		position:relative;
		height:100%;
	}
	.card-description{
		height:0px;
		position:absolute;
		bottom:0;
		width:100%;
		border-top:solid 3px orange;
		background: white;
		opacity:0;
	}
	&:hover{
		.card-content{
			background:yellow;
			opacity:.1;
		}
		.card-description{
			transition:800ms;
			height:100px;
			opacity:1;
		}
	}
}
*/
.departamento{
	width:100%;
	height:0;
	transition:1s;
	opacity:0;
	/*overflow:auto;*/
	position:relative;
}
.departament-active{
	padding:30px;
	transition:500ms;
	min-height:600px !important;
	opacity:1;
}
.categorias{
	margin-top:30px;
	column-count:5;
}
.bold{
	font-weight:bold;
}
.select-wrapper{
	background:#F5F5F5;
}
.select-wrapper input.select-dropdown{
	border-bottom: none;
	color:#4D4D4D;
}
.select-wrapper input.select-dropdown:focus {
	border-bottom: none;
}
.progress{
	background:#ABABAB;
}
.points{
	position:relative;
	&:before {
		content: "";
		height:10px;
		width:10px;
		background:black;
		position:absolute;
		left:0px;
		top:-3px;
		border-radius:100px;
	}

	&:after {
		content: "";
		height:10px;
		width:10px;
		background:#ABABAB;
		position:absolute;
		right:0px;
		top:-3px;
		border-radius:100px;
	}
}
.black-points{
	position:relative;
	&:before {
		content: "";
		height:10px;
		width:10px;
		background:black;
		position:absolute;
		left:0px;
		top:-3px;
		border-radius:100px;
	}

	&:after {
		content: "";
		height:10px;
		width:10px;
		background:black;
		position:absolute;
		right:0px;
		top:-3px;
		border-radius:100px;
	}
}
.direction-active{
	border: 2px solid #FBB19A;
	box-shadow: 2px 2px 12px 3px rgba(255, 228, 219, 0.97)
}
/* -------------------- CONTAINERS/LAYOUTS ---------------------------*/
.container-xlarge{
	margin: 0 auto;
	width: 95%;
}
.container-large{
	margin: 0 auto;
	width: 90%;
}
.container-medium{
	margin: 0 auto;
	width: 85%;
}
/* ----------------- TIPOGRAFIA / TEXTOS -------------- */
.source-sans{
	font-family: 'Source Sans Pro', sans-serif;
}
.semi-bold{
	font-weight: 500;	
}
.bold{
	font-weight: 700;	
}

/* ---------------- MARGINS /PADDINGS ------------------ */
.margin-5{
	margin:5px;
}
.margin-10{
	margin: 10px;
}
.margin-top-5{
	margin-top:5px!important;
}
.margin-top-10{
	margin-top: 10px;
}
.margin-top-20{
	margin-top: 20px;
}
.margin-top-50{
	margin-top: 50px;
}
.margin-bottom-10{
	margin-bottom: 10px;
}
.margin-bottom-20{
	margin-bottom: 20px;
}
.margin-bottom-50{
	margin-bottom: 50px;
}
.margin-y-20{
	margin:20px 0
}
.margin-y-50{
	margin:50px 0;
}
.padding-0{
	padding: 0!important;
}
.padding-r-0{
	padding-right: 0!important;
}
.margin-0{
	margin: 0;
}
.margin-left-0{
	margin-left: 0!important;
}
.margin-top-10{
	margin-top: 10px;
}
.margin-top-50{
	margin-top: 50px;
}
.margin-top-95{
	margin-top: 95px;
}
.padding-10{
	padding: 10px;
}
.padding-5{
	padding: 5px;
}
.padding-20{
	padding: 20px!important;
}
.padding-x-5{
	padding: 5px 0;
}
.padding-y-10{
	padding: 0 10px;
}
.padding-left-5{
	padding-left: 5px!important;
}
.padding-top-50{
	padding-top: 50px;
}
.padding-tab{
	padding: 10px 0!important;
}
/** BACKGROUNDS**/
.bg-perfil{
	background-color: #F5F5F5;
	padding: 10px;
}
.bg-blue{
	background-color: #269FCF;
}
.bg-light-gray{
	background-color: #F5F5F5;
}

/***MIS CUPONES PERFIL***/
.btn-agregar{
	text-transform: capitalize;
	background-color: #F5F5F5;
	padding:0 10px;
	width:160px;
	border-radius:none;
	font-size: 14px;
	font-weight: 500;
	color:$dark-grey;
	&:hover{
		background-color: #4D4D4D;
		color: #F5F5F5;
	}		
}
.btn-blue{
	text-transform: capitalize;
	background-color: #269FCF;
	padding:0 10px;
	width:160px;
	border-radius:none;
	font-size: 14px;
	font-weight: 500;
	color:white;
	&:hover{
		background-color: #4D4D4D;
		color: #F5F5F5;
	}		
}
.btn-agregar-sm{
	text-transform: capitalize;
	background-color: #F5F5F5;
	padding:0 10px;
	width:120px;
	border-radius:none;
	color:$dark-grey;
	font-size: 14px;
	font-weight: 500;
	&:hover{
		background-color: #4D4D4D;
		color: #F5F5F5;
	}	
}
.btn-transparent{
	text-transform: capitalize;
	background-color:white;
	box-shadow: none;
	padding:0 10px;
	width:120px;
	border-radius:none;
	color:$dark-grey;
	font-size: 14px;
	font-weight: 500;
	&:hover{
		background-color:transparent;
		border:1px solid $dark-grey;
		box-shadow: none;
	}	
}

.codigo-th{
	width: 150px;
	color: $dark-grey;
	font-size: 14px;
}
.status-th{
	font-size: 12px;
	font-weight: 400;
}
.success-green{
	color: #37B73C;
}
.info-blue{
	color: #269FCF;
}
.btn-modal{
	margin-right:10px;
	display:block;
	width:125px;
	height:30px;
	font-size:12px;
	font-weight:500;
	line-height:14px;
	text-transform:none;
	font-style:normal;
	padding:8px;
	text-align:center;
}

/* ----------- LOADER COMPONENT ----------- */
.loader-container {
    position:relative;/* absolute */
    left: 0;
    right: 0;
    height: 70vh;
    z-index: 10000;
    text-align: center;
}

.loader-content {
    position: relative;
    top: 25%;
}

.loader {
    width: 4em; /* 30px [16px base font]*/
    height: 4em; /* 30px [16px base font]*/
	border-top: 10px solid aliceblue;
	border-right: 10px solid aliceblue;
	border-bottom: 10px solid aliceblue;
	border-left: 10px solid #8c618d;
	border-radius: 50%;
	animation-name: spin;
	animation-duration: 3s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	display: inline-block;
    zoom: 1;
}
.bodyHeight{min-height:42vh;}
.loader-text {
    display: block;
	color: #4D4D4D;
    position: relative;
    text-align: center;
    margin-top: 0.5em;
    font-size: 1.5em;
    font-family: sans-serif;
    font-weight: 700;
    -webkit-animation: bounce 3s linear infinite;
            animation: bounce 3s linear infinite;
    letter-spacing: 0.5em;
}
.section-tittle{
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 36px;
	font-weight: 900;
	text-transform: uppercase;
	margin: 15px;
}
.section-title-parent{
	display: flex;
	max-width: 800px;
	align-items: center;
	height: 150px;
  }
  
.section-title-child{
	height: 3px;
	width: 36px;
  }

@keyframes spin {
	0% {
	  transform: rotate(0deg);
	  border-left:10px solid #4c00ff ;
	}
	25%{
	  transform: rotate(360deg);
	  border-left:10px solid gold;
	}
	50%{
	  transform:rotate(720deg);
	  border-left:10px solid palegreen;
	}
	
	75%{
	  transform: rotate(1080deg);
	  border-left:10px solid aqua;
	}
  
	100% {
	  transform: rotate(1440deg);
	  border-left:10px solid #4c00ff ;
	}
  
  }
@-webkit-keyframes bounce {
    0% {
        top: 0;
    }
    25% {
        top: 0.625em; /* 10px/16px */
    }
    50% {
        top: 0;
    }
    75% {
        top: 0.625em; /* 10px/16px */
    }
    100% {
        top: 0;
    }
}

@keyframes bounce {
    0% {
        top: 0;
    }
    25% {
        top: 0.625em; /* 10px/16px */
    }
    50% {
        top: 0;
    }
    75% {
        top: 0.625em; /* 10px/16px */
    }
    100% {
        top: 0;
    }
}
@media (max-width: 640px){/* Tamaño s*/
    .bg-perfil{
        padding: 10px;
    }
    .hide-on-640{
        display:none !important;
    }
    .show-on-640{
        display:block !important;
    }
}

