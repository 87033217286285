.p-4-8{padding:4px 8px 8px 8px!important;}
.brad{
    background-color: #FCFCFC;
    padding:20px 35px!important;
    font-size:16px;
    font-weight: 500;
    margin:0;
    width:100%;
}
.cont-item-vistos .prod-item{margin:0;padding:16px!important;}
.cont-item-vistos{margin-bottom:40px;}
.cont-result{padding:20px 40px}
  
  @media (max-width:990px){
    .cont-result{padding:20px}
  }
  @media (max-width:550px){
   .cont-result{padding:10px}
    .cont-item-vistos .prod-item{margin:0;padding:0 1px!important;}
  }
  @media (max-width:550px){
    .cont-result{padding:4px!important}
  }
