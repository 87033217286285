.cart-container{
    padding:50px; 
    min-height:4vh; 
    margin-bottom:0px;
}
.cart-product-card{
    padding: 23px 15px;
    border-bottom: 1px solid #F3F3F3;
    background-color: #FFFFFF;
}
.cart-product-card .select-wrapper{
    padding:0 10px;
}
.orange-brick-cart{
    color:#DF3F0C;
    font-weight: 700;
    font-size: 16px;
    padding: 16px 0 5px 0;
 }
.cart-container-short{
    padding:90px 50px;
}
.cart-img-prod-cont{
    height:200px;
}
.cart-img-prod{
    object-fit: cover;
}
.cart-prod-info h4{
    font-size: 14px;
    margin: 0;
    text-align: justify;
    font-weight: 500;
    color: black;
}
.cart-prod-descripcion{
    font-size:14px;
    text-align:justify;
    color:#818181; 
}
.cart-prod-btn{
    width: 207px;
    text-transform: capitalize;
    color: white;
    font-size: 14px;
    font-weight: 700;
}

.cart-prod-btn-black{
    width: 262px;
    text-transform: none;
    color: white;
    font-size: 14px;
    font-weight: 700;
}

/* ----------- MEDIA QUERY  */
@media (max-width: 1600px){/*Tamaño xl*/
}
@media (max-width: 1500px){
}
@media (max-width: 1200px){/*Tamaño l*/
}
@media (max-width: 960px){ /*Tamaño m*/
    .cart-container{
        padding:25px;
    }
    .cart-container-short{
        padding:45px 25px;
        min-height:55vh;
    }
    .cart-img-prod-cont{
        height:140px;
    }
    .cart-img-prod{
        object-fit: cover;
    }
}
@media (max-width: 820px){  
}
@media (max-width: 640px){/* Tamaño s*/
    .cart-img-prod-cont{
        height:auto;
        width:45vw;
    }
    .cart-prod-info{
        width:35vw;
	margin-left:5px;
    }
    .cart-prod-btn{
        width:100%;
        margin: 10px 0;
    }
    .cart-container-short{
        padding:45px 10px;
    }
    .cart-container{
        padding:5px;
    }
}
