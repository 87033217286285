.m-p-0{
    margin:0!important;
    padding: 0!important;
}
.m-0{
    margin:0!important;
}
.p-0{
    padding: 0!important;
}
.pt-10{
    padding-top:10px!important;
}
.pt-40{
    padding-top:40px!important;
}
.ptb-80{
    position: relative;
    padding:80px 0!important;
}
.center-y-x{
    display: flex;
    justify-content: center;
    align-items: center;
}
.height80{
    min-height: 80vh;
}
.cont-user{
    border-radius: 100%;
    height:80px;
    width: 80px;
}

.input-height35{
    height:35px!important;
}
.input-field>label{
    transform: translateY(1px);
}
.active{
    font-weight:bold;
    margin:0;
    margin-top:-5px;
    padding:0;
}

.font-black{
    color:#000000;
    font-weight:500;
    line-height: 1.6;
}
.font-red{
    font-weight:500;
    line-height: 1.6;
}
.style-ojo{
    position:absolute; right:10px; padding-top:6px; cursor: pointer;
}

/* --------------------MEDIA QUERIES  ------------------*/

@media (max-width: 1600px){/*Tamaño xl*/
}
@media (max-width: 1200px){
}
@media (max-width: 960px){ /*Tamaño m*/
}
@media (max-width: 820px){
}
@media (max-width: 640px){/* Tamaño s*/
    .ptb-80{
        position: relative;
        padding:20px 0!important;
    }
    .bg-perfil{
        background-color: #E5E5E5;
        padding: 0;
    }
}
