.prod-info{
    padding:17px;
}
.prod-card{
    margin:0;
    padding:10px!important;
}
.prod-container{ 
    height: 380px;
    border: none;
    background-color: #F7F7F7;
    border-radius:12px;
    padding:0;
    width:220px;
} 
.prod-container:hover{
    -webkit-box-shadow: 3px 1px 10px 0px rgba(153,153,153,.5);
    -moz-box-shadow: 3px 1px 10px 0px rgba(153,153,153,.5);
    box-shadow: 3px 1px 10px 0px rgba(153,153,153,.5);
    -webkit-transition: all 0.3s ease;                  
    -moz-transition: all 0.3s ease;                 
    -o-transition: all 0.3s ease;   
    -ms-transition: all 0.3s ease;          
    transition: all 0.3s ease;
}
.prod-cont-img{
	/*background:#ffffff;*/
    border-radius:12px 12px 0 0;padding:0; margin:0; height:230px; width:220px;text-align: center;
}
.prod-cont-img img{
   object-fit:scale-down!important;border-radius:12px 12px 0 0; height:100%; width:100%; 
}
.prod-name{
    font-size:16px;
    font-weight:600;
    color:#000000;
    line-height: 1;
    text-align:left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding:0;
    padding-top:8px!important;
}
.prod-descripcion{
    font-size: 12px;
    color:#000000;
    margin:0;
    height:39px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin:0;
    padding:0;
    line-height: 1;
    text-align:left;
    padding:15px 0!important;
}
.prod-price{
    font-size:16px;
    font-weight:500;
    margin:0!important;
    padding:0!important;
    padding-top: 10px!important;
    letter-spacing: -1px;
    padding-top:8px!important;
}

.prod-cont-aling{
    display:flex;
    align-items:center;
    justify-content:center
}
.prod-car-bg{
    width:35px;
    height:35px;
    padding:3px;
    border-radius:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}
.prod-car-bg img{
    width:19px;
    object-fit:cover;
}
.prod-cont-texts{margin:0;
    padding:0!important;
    padding-right:10px!important;
}
.prod-verificar{
    font-family: 'Caveat', cursive;
    font-size: 12px;
}
.prod-badge{
    background-color:#4D4D4D;
    border-radius:5px;
    color:#ffffff;
    font-size:8px;
    margin:0;
    padding:3px 4px;
    width: auto!important;
    text-align:center;
}
    

@media (max-width:1200px){
    .cont-card-movil{ width:25%!important; }
}
@media (max-width:990px){
    .cont-card-movil{ width:33%!important; }
}
@media (max-width:640px){
    .cont-card-movil{ width:50%!important; }
    .prod-card {
        padding:4px!important;}
	.prod-cont-img{
		height:100px;
		width:100px;
	}
	.prod-container{
		width:130px;
		height:180px;
		border-radius: 0px;
	}
	.prod-info{
		padding:5px;
	}
	.prod-name{
		padding:0px !important;
	}
	.prod-descripcion{
		display:none;
	}
}
@media (max-width:550px){
    .cont-card-movil{ width:50%!important; }
    .prod-card {
        padding:8px!important;}
}
@media (max-width:375px){
    .cont-card-movil{ width:100%!important; }
}
