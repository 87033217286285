.busquedas{
    padding:6px 10px!important;
    font-size:18px;
    font-weight: 500;
}
.btn_elimina{
    cursor: pointer;
    background-color: #F5F5F5;
    color:#ABABAB;
    border:solid 1px transparent!important; padding:5px;
    /*border-radius: 6px;  
    box-shadow:1px 1px 2px 0px rgba(58, 45, 45, 0.4);
    -webkit-box-shadow:1px 1px 2px 0px rgba(0,0,0,0.4);
    -moz-box-shadow:1px 1px 2px 0px rgba(0,0,0,0.4);*/
}
.btn_elimina:hover{
    color:#000000;
}
.bg-grey{
    background:#F5F5F5;
}
.section-container{
    min-height: 25vh!important;
    padding:1.5em 0!important;
} 
.section-container a{color:#4D4D4D}
.search-tag{
    display: inline-block;
    padding: 10px 60px;
    margin: 15px;
    background-color: #FFFFFF;
    min-width: 160px;
    text-align: center;
    cursor: pointer;
}
.search-tag:hover{
    background-color: #4D4D4D;
    color: #FFFFFF;
}
.margin-50{
    margin:50px;
}
.padding-y-20{
    padding: 0 20px;
}
.breadcrum-container{
    background:#FCFCFC;
    padding-left:2em;
}
.breadcrum-link{
    font-size: 18px; 
    font-weight: 500;
    color: black!important;
}

.breadcrum-link ::before{
    color: black!important;
}

.card-container{ 
    box-shadow:0 4px 8px 0 rgba(0,0,0,0.2); 
    background:#EFEFEF;
    border-radius:5px;
    position:relative;
} 

.card-cont-img{
    margin:0;
    padding:0;
    height:250px;
}
.card-cont-img img{
    height:100%;
    width:100%; 
   border-bottom:2px solid #F7C701; 
}
.card-name{font-size: 15px;
    line-height: 1;
    height: 70px;
    overflow: hidden;
    color:#4D4D4D;
    overflow-x:hidden;
    padding: 10px 15px 0 15px;
}
.card-descripcion{  
    padding: 5px 15px!important;
    margin: 0;
    height:50px;
    overflow:auto;
    font-size:14px;
    color:#4A4F49;
    text-align:justify;
}
.card-price{
    padding: 15px!important;
    font-size:23px;
    font-weight:700;
}
.card-price span{
    font-size:12px;
}
.card-cont-aling{
    padding:10px!important;
    display:flex;
    align-items:center;
    justify-content:center
}
.card-car-bg{
    width:35px;
    height:35px;
    padding:3px;
    border-radius:100%;
    display:flex;
    align-items:center;
    justify-content:center;
}
.card-car-bg img{
    width:19px;
    object-fit:cover;
}

@media (max-width:1460px){
    .section-container{padding: 1.8em!important;}
  }
  @media (max-width:1200px){
    .section-container{padding: 1.5em!important;}
  }
  @media (max-width:990px){
    .section-container{padding: 1em!important;}
    .card-name{ font-size: 12px; }
    .card-price{ font-size:14px; }
    .search-tag{margin: 6px 15px 6px 0;padding: 10px 30px;}
    .container-large{width:95%;}
  }
  @media (max-width:640px){
    .section-container{padding: .5em!important;}
    .search-tag{padding: 10px 20px;}
  }
  @media (max-width:425px){
    .card-cont-img{height:190px;}
    .search-tag{width:100%;}
    .card-name{height:40px;}
  }
  @media (max-width:380px){
    .card-cont-img{height:240px;}
  }