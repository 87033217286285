.cont-btn{
    display:inline-block; 
    height:50px; 
    width:50px; 
}
.btn-letra{
    background-color:#E8E8E8;
    border:solid #999999 1px;
    height:40px;
    width:40px;
    cursor: pointer;
}
.letra{
    font-size:20px;
    font-weight:700;
}
.palabra{
    display:inline-block; 
    width:16%; 
    font-size:14px;
    font-weight:600;
    vertical-align: top;
    text-align: right;
}
.definicion{
    display:inline-block; 
    width:74%;  
    font-size:14px;
    vertical-align: middle;
    line-height: 1;
}
@media (max-width: 640px){/* Tamaño s*/
  .palabra{
      width: 25%;
      text-align: center;
  }
  .definicion{
      width: 77%;
      
  }
}