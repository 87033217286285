.barra{background-color: #F8F8F8;
    padding:20px 40px!important;
    font-size:18px;
    font-weight: 500;
    margin:0;
}
.active {margin-top: 0;font-weight:900!important;}
.tabs-info{ margin: 0;
    border:solid 1px #D6D6D6;
    font-size: 14px;
    font-weight:100;
}
.cont-icon-activo{background-color: #4D4D4D!important;}
.cont-icon{background-color:#ABABAB;
    border-radius: 100%;
    padding:2px;
    width: 20px;
    height:20px;
    display: inline-block;
    margin-right:10px;
}
.cont-icon i{color:#ffffff}
.pedido-subtitle{
    font-size: 18px;
    font-weight: 500; 
}
.pedido-subtitle span{
    font-size: 12px;
    font-weight: 300;
}
.hr-subtitle{
    height:4px;
    background:black;
    width: 20em;
    margin-bottom:.9em;
}
.dir-container{
    padding:30px!important;
}
.bloque{
    margin-top: 20px;
}
.titulo-bloque{
    font-size: 16px;
    color: #4D4D4D;
    font-weight:700;
}
.texto-bloque{
    font-size: 14px;
}
.guia-bloque{
    font-size: 14px;
    color: #4D4D4D;
    font-weight: 700;
}
.vertical-divider {
    border-right:1px solid #D6D6D6;
    border-left:1px solid #D6D6D6;
    height:100%;
    position:absolute;
    left:0;
    top:10px; 
}

#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}
.producto-bloque-container{
    height: 200px;
    overflow-y:scroll;
    overflow-x: hidden;
}
.producto-bloque{
    margin-top: 20px;
}
.producto-bloque .titulo{
    font-size: 12px;
    font-weight: 700;
    color: black;
}
 .producto-bloque .texto{
    font-size: 12px;
 }
 .producto-bloque .precio{
    font-size: 12px;
    font-weight: 700;
    color: #4D4D4D;
 }
 .pedido-totals{
    margin-top: 20px;
 }
 .orange-brick{
    color:#DF3F0C;
    font-weight: 700;
 }
.hr-large{
    height:4px;
    background:black;
    margin:1em;
}
/* ----------- MEDIA QUERY  */
	@media (max-width: 1600px){/*Tamaño xl*/
	}
	@media (max-width: 1500px){
	}
	@media (max-width: 1200px){/*Tamaño l*/
	
	}
	@media (max-width: 960px){ /*Tamaño m*/
	
	}
	@media (max-width: 820px){
	}
	@media (max-width: 640px){/* Tamaño s*/
        .img-prod{height:115px;}
        .img-prod img{max-height:100%;}
	}

