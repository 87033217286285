.cont-categoria{
    display:inline-block;
    margin:0;
    padding:0;
    vertical-align:top; 
    border:'solid purple 4px';
    width:25%;
}
.cat-img{
    background-position:center!important; background-size:cover!important; height:370px; 
    cursor:pointer; position:relative; margin:0; padding:0; z-index:0;
}

.cat-img:hover .cat-hover-container{
    height:100%;
    width: 100%;
    transition-property: all;
}
.cat-hover{
    bottom: 0px;
    right: 0px;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    transition: opacity 1s;
    transition-property: all;
    bottom: -110px!important;
}
.cat-img:hover .cat-hover{
    opacity:1!important;
    bottom:0!important;
}
.cat-hover-categoria{
    background-color: rgba(255, 255, 255, 1);
    min-height: 117px;
    font-size: 14px;
    line-height:1;
    padding:4%;
    width: 100%;
}
.cat-hover-categoria-titulo{
    color: #4D4D4D;
    font-size:26px;
    font-weight:900;
    line-height: 1;
    padding: 6px;
    letter-spacing: -2px;
    text-transform: uppercase;
}
.cat-active{margin:0; padding:2% 0; position:flex!important; width:100%!important; z-index:1;
}
.desplegable{
    /*min-height:480px; */
    background-color: #F5F5F5!important; 
    left:0;
    margin:0;
    padding:0 109px;
    position:absolute; 
    text-align: center;
    width:100vw;
    z-index:2;
    z-index: 999999;
}
.desplegable h4{
    font-size:36px;
    font-weight:700;
    text-transform: uppercase;
}
.desplegable h5{
    font-size: 16px;
    font-weight:500;
    padding:10px 0 20px 0;

}
.cat-name{
    /*height:250px; margin:0; overflow-y:scroll!important; */
    padding:0; text-align: left;  width:100%;
}
/*.cat-name::-webkit-scrollbar-track{ 
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); background-color: #F5F5F5; 
}
.cat-name::-webkit-scrollbar{
    background-color: #F5F5F5; width: 16px; 
}
.cat-name::-webkit-scrollbar-thumb{ 
    background-color:#999999; border-radius:10px; 
}*/
.listado-categorias{position:absolete; z-index:999;}

.nombre{
    display:inline-block; width:20%; font-size:14px;
    text-transform: capitalize;
    line-height: 1;
    padding: 5px 15px;
}
.nombre a{
    font-weight:400;
    font-size:14px;
}
.cont-card-depa p{
    margin-top:.5em;
}


@media (max-width:1200px){
    .cont-card-depa{
        width:50%!important;
    }
    .cont-categoria{width:33%;}
    .nombre { font-size: 12px;padding:2px 8px}
    .desplegable{padding:0 40px!important;}
}
@media (max-width:923px){
    .cont-categoria{width:50%;}
    .nombre {
        width: 33%;
    }
    .cat-active h4{font-size: 16px;}
    .cat-active h5{font-size: 14px;}
    .desplegable{padding:0 30px!important;}
}
@media (max-width:640px){
    .cont-categoria{
	width:50%;
    }
    .cat-img{
	height:200px;
    }
    .cont-card-depa{
        width:100%!important;
    }
    .desplegabble{padding:0 20px!important;}
    .desplegable h4{
        font-size:16px;
        font-weight: bold;
    }
    .desplegable h5{
        font-size:14px;
    }
}
@media (max-width:550px){
    .nombre {
        width: 50%;
    }
    .desplegable{padding:0 10px!important;}
}
