.lateral-cat{margin:0; padding:5px; padding-top:20px; display:inline-block; vertical-align: top; width:180px;}
.lateral-list{display:inline-block; vertical-align: top; margin:0; padding:0; padding-left:10%; width:calc(100% - 185px); }
.nameCat{font-size: 16px;
    font-weight: 600;
    color: #000000;
    line-height: 1;
    text-align: left!important;
}
.cont-listado{
    padding:30px 50px 50px 50px;
}
.cont-card-cat{
    width:11%!important;
}

.collapsible-header {
    font-size: 14px;
    font-weight: 700;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    line-height: 1;
    border-bottom: 1px solid transparent;
    padding: 8px;
    margin: 0;
}
@media (max-width:1800px){
    .cont-card-cat{
        width:12%!important;
    }
}
@media (max-width:1600px){
    .cont-card-cat{
        width:14%!important;
    }
}
@media (max-width:1450px){
    .cont-card-cat{
        width:16.6%!important;
    }
}
@media (max-width:1200px){
    .cont-card-cat{
        width: 33%!important;
    }
    .cont-listado{
        padding:0px 40px 40px 40px
    }
    .cont-card-cat{
        width: 25%!important;
    }
}
@media (max-width:990px){
    .cont-listado{
        padding:0px 20px 20px 20px
    }
    .cont-card-cat{
        width: 33.3%!important;
    }
}
@media (max-width:682px){
    .cont-card-cat{
        width:33%!important;
    }
}
@media (max-width:640px){
    .cont-listado{
        padding:0px 10px 10px 10px
    }
    .prod-price{font-size:12px!important;}
    
}
@media (max-width:550px){
    .cont-listado{
        padding:0px 5px 5px 5px
    }
    .prod-card {
        margin: 0;
        padding: 4px!important;
    }
    .cont-card-cat{
        width:50%!important;
        padding:4px;
    }
}
@media (max-width:380px){
    .cont-card-cat {width: 100%!important;}
}
